<template>
  <div class="d-block d-lg-none">
    <div class="row">
      <div class="col-10 mb-0 col-sm-9 col-lg-10">
        <p class="mypracticeMobile" style="margin-left: 20px;">My Practice</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2">
        <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
    </div>
    <div class="hr-border" ></div>
  </div>
<div class="mobileStyleDoctor">
    <div class="row">
      <div class="col-12">
        <h5>I offer:</h5>
      </div>
    </div>
    <div>
    </div>
    <div class="row clinicTextAlign" v-for="(dropdown, indexk) in consultationData" :key="indexk">
          <!-- All Consultation  -->
      <p class="checkbox-inline heading py-1">
        <span v-if="dropdown.consultation === 'Home consultation' || dropdown.consultation === 'Clinic consultation'">
            <input type="checkbox" class="me-2 "  v-model="dropdown.isActive" @change="getHomeOrClinicChange($event,dropdown)" style="cursor: pointer;" />
            {{ dropdown.consultation }}
        </span>
        <span v-else>
            <input type="checkbox" class="me-2" :name="dropdown.consultation" v-model="dropdown.isActive" 
                   :disabled="!isHomeOrClinicChecked()" @change="updateButtonState"/>
            {{ dropdown.consultation }}
        </span>
      </p>
      <div >
        <div v-if="popupAllClinic && dropdown.consultation === 'Clinic consultation'" class="popup">
      <div class="popup-content">
        <p>Disabling the checkbox will delete all clinics. Are you sure?</p>
        <div style="display: flex; justify-content: space-evenly">
          <button @click="deleteAllClinic(dropdown)" class="yes-button">Yes</button>
          <button @click="clinicAllPopup" class="no-button">No</button>
        </div>
      </div>
    </div>
         <!-- Clinic Consultaion get call fields  -->
        <div v-if="dropdown.consultation === 'Clinic consultation' && dropdown.isActive == true">
          <div class="clinic_section text-align text-left" v-for="(clinicConsult, index) in clinicConsultationDoctor" :key="index" style="margin: 20px 0px">
            <div class="box-shadow2 cardBorder">
              <div class="row">
                <div>
                  <span class='cross-icon' >
                      <font-awesome-icon :icon="['fas', 'times-circle']" @click="showPopup()" />
                    </span>        
                  </div>
                  <div v-if="isPopupVisibleClinic && this.clinicConsultationDoctor.length>1" class="popup">
      <div class="popup-content">
        <p> Are you sure you wish to delete his clinic?</p>
        <div style="display: flex; justify-content: space-evenly;">
          <button @click="deleteClinic(clinicConsult)" class="yes-button bg-color">OK</button>
          <button @click="closePopup" class="no-button bg-orange-color">Cancel</button>
        </div>
      </div>
    </div>
    <div v-if="isPopupVisibleClinic && this.clinicConsultationDoctor.length<=1"  class="popup">
      <div class="popup-content">
        <p> There should be at least one clinic if Clinic consultation is enabled.</p>
        <div style="display: flex;justify-content: center;">
          <button @click="closePopup" class="yes-button">OK</button>
        </div>
      </div>
    </div>
     </div>
      <div class="row" v-if="clinicConsultationDoctor.length > 0">
       <div class="col-12" >
              <div class="form-group my-2">
                  <label class="receiveConsultation">Clinic website</label>
                  <input type="text" v-model="clinicConsult.clinicWebsite" class="form-control" name="clinic_website"
                     id="clinic_website" @click="getDataToEditConsultation(clinicConsult,index,dropdown)" />
                </div>
                
              </div>
              </div>
              <div class="row"> 
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Name of clinic <span class="star">*</span> </label>
                  <input type="text" v-model="clinicConsult.clinicName" class="form-control" name="clinic_name" id="clinic_name"  
                  @click="getDataToEditConsultation(clinicConsult,index,dropdown)"  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Short name of clinic </label>
                  <input type="text" v-model="clinicConsult.short_name" class="form-control" name="short_name" id="short_name"  
                  @click="getDataToEditConsultation(clinicConsult,index,dropdown)"  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6 d-block d-lg-none">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                  <input type="text"  v-model="clinicConsult.mobile" class="form-control" name="clinic_phone_number"
                     id="mobile"   @click="getDataToEditConsultation(clinicConsult,index,dropdown)" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Address 1 <span class="star">*</span></label>
                  <input  type="text" v-model="clinicConsult.current_address" class="form-control" name="current_address" id="current_address" @click="getDataToEditConsultation(clinicConsult,index,dropdown)">
                </div>
              </div>
            </div>
            <div class="row">
            <div class="col-6 col-lg-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Address 2</label>
                  <input  type="text" v-model="clinicConsult.option_address" class="form-control" name="option_address" id="option_address" @click="getDataToEditConsultation(clinicConsult,index,dropdown)">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-4">
                <div class="form-group my-2">
                  <label class="receiveConsultation">City <span class="star">*</span></label>
                  <input  type="text" v-model="clinicConsult.city" class="form-control" name="city" id="city" @click="getDataToEditConsultation(clinicConsult,index,dropdown)">
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                 
                  <input type="text"  v-model="clinicConsult.pincode" maxlength="6" class="form-control" name="pincode"
                     id="pincode" @input="validatePincodeInput"   @click="getDataToEditConsultation(clinicConsult,index,dropdown)" />
                </div>
                
              </div>
              <div class="col-12 col-md-6 col-lg-4 ">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                  <input type="text" aria-label="mobileNo"  maxlength="10" v-model="clinicConsult.mobile" class="form-control" name="clinic_phone_number"
                     id="mobile" :class="{ 'is-invalid': mobileError }" @input="validatePhoneInput"  @click="getDataToEditConsultation(clinicConsult,index,dropdown)" />
                    </div>
                    <div v-if="mobileError" class="text-danger">Please enter a valid 10-digit phone number</div>
     
              </div>
              <!-- <div class="col-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">District <span class="star">*</span></label>
                  <input  type="text" v-model="clinicConsult.district" class="form-control" name="district" id="district" @click="getDataToEditConsultation(clinicConsult,index,dropdown)">
                </div>
              </div> -->
            </div>
        
            <!-- <div class="row">
              <div class="col-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">State <span class="star">*</span></label>
                <input  type="text" v-model="clinicConsult.state" class="form-control" name="state" id="state" @click="getDataToEditConsultation(clinicConsult,index,dropdown)">
              </div>
            </div>
            </div> -->
<button v-if="clinicConsult.is_publish_clinic " 
        class="btn btn-blue-color text-white" 
        @click="updateConsultation()"
        :disabled="!isFormValid || !clinicConsult.isedit || isButtonEnabled">
          Update my clinic
</button>

<button v-else 
        class="btn btn-blue-color text-white" 
        @click="updateConsultation()"
        :disabled="!isFormValid || !clinicConsult.isedit || isButtonEnabled">
        Publish my clinic
</button>

            </div>
         </div>
          </div>
      </div>
      <!-- Clinic Consultaion for Multiple Post. enable while click the Plus button -->
      <div v-if="dropdown.consultation === 'Clinic consultation' && dropdown.isActive && addclinic">
          <div class="clinic_section text-align text-left"  style="margin: 20px 0px" v-for="(clinicdata , index) in clinicForms" :key="index" >
            <div class="box-shadow2 cardBorder">
            <div class="row" v-if="clinicConsultationDoctor.length<1 && showClinicWebsite(index)">
              <div class="col-12" >
                <div class="form-group my-2" >
                  <label class="receiveConsultation">Clinic website </label>
                  <input type="text" v-model="clinicdata.clinic_website" class="form-control" :name="'clinic_website' + index"  :id="'clinic_website' + index" />
                </div>
              </div>
            </div>
              <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Name of clinic <span class="star">*</span></label>
                  <input type="text" v-model="clinicdata.clinic_name" class="form-control" :name="'clinic_name' + index" :id="'clinic_name' + index"    />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Short name of clinic</label>
                  <input type="text" v-model="clinicdata.short_name" class="form-control" :name="'short_name' + index" :id="'short_name' + index"    />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-block d-lg-none">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                  <input type="text" v-model="clinicdata.doctorMobile" maxlength="10" class="form-control" :name="'clinic_phone_number' + index" :id="'mobile' + index" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Address 1 <span class="star">*</span></label>
                  <input  type="text" v-model="clinicdata.current_address" class="form-control" :name="'current_address' + index" :id="'current_address' + index">
                </div>
              </div>
            </div>
            <div clas="row">
              <div class="col-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Address 2</label>
                  <input  type="text" v-model="clinicdata.option_address" class="form-control" :name="'option_address' + index" :id="'option_address' + index">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group my-2">
                  <label class="receiveConsultation">City <span class="star">*</span></label>
                  <input  type="text" v-model="clinicdata.city" class="form-control" :name="'city' + index" :id="'city' + index">
                </div>
              </div>
              
            
           
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                  <input type="text" v-model="clinicdata.doctorPincode" class="form-control" :name="'pincode' + index"
                    :id="'pincode' + index"  maxlength="6"/>
                </div>
              </div>
            
              <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                  <input type="text" v-model="clinicdata.doctorMobile" maxlength="10" class="form-control" :name="'clinic_phone_number' + index" :id="'mobile' + index" />
                </div>
              </div>
   

          </div>

           <div class="row">
              <div class="col-12 col-md-6 col-lg-2 mt-3">
        <button class=" btn btn-blue-color text-white" @click=" saveClinicConsultation()"
        :disabled="areFieldsEmpty" style="width:100%">{{ "Publish my clinic" }}
        </button>
      </div>
          </div>
         
          </div>
        </div>
        
      </div>
      <div class="row" v-if="dropdown.consultation === 'Clinic consultation' && dropdown.isActive">
          <div class="col-9">
            <hr class="hr-border"  v-bind:class="[popupAllClinic||isPopupVisibleClinic ||isPopupVisibleHomePincode||isPopupVisibleHome? 'hideOpacity' : '']"/>
          </div>
          <div class="col-3">
            <div class="form-group text-right">
              <span type="button" class="btn btn_add " id="btn_clinicconsult_add"  @click="addEmptyClinicForm()" 
                style="width: 55px; height: 50px; font-size: 30px; margin-top: -10px; "  :disabled="areFieldsClinicEmpty" :class="[areFieldsClinicEmpty ? 'disableCreateBtn' : 'clinicCreateBtn']"> 
                <font-awesome-icon :icon="['fas', 'plus-circle']"   />
            </span>
            </div>
          </div>
        </div>
      <!-- Get Home Consultation  -->
      <div class="home_section my-3" v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive && homeConsultationDoctor.length" >
        <div class="box-shadow2 cardBorder" >
          <div class="row">
                  <div v-if="isPopupVisibleHome && dropdown.consultation === 'Home consultation'" class="popup">
      <div class="popup-content">
        <p>Disabling the checkbox will delete your home consultation practice info in all pincodes. Are you sure?</p>
        <div style="display: flex; justify-content: space-evenly;">
          <button @click="deleteHome(homeClinicid,dropdown)" class="yes-button">Yes</button>
          <button @click="closeHomePopup" class="no-button">No</button>
        </div>
      </div>
    </div>
              </div>
          <h6 class="my-3 subHeading">Areas where I offer home consultation and charges</h6>
          <div class="row my-3" >
            <div class="col-lg-6 col-md-6 col-12" >
              <label class="receiveConsultation">Name of clinic <span class="star">*</span></label>
              <input type="text" v-model="homeClinicName" @input="handleclinicNameChange" class="form-control" name="homeClinicName"
                id="homeClinicName"/>
            </div>
            <div class="col-lg-6 col-md-6 col-12" > 
              <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
              <input type="text" :class="{ 'is-invalid': mobileHomeError }" v-model="homeClinicMobile" maxlength="10" @input="handleInputChange" class="form-control" name="homeClinicMobile"
                id="homeClinicMobile"/>
                <div v-if="mobileHomeError" class="text-danger">Please enter a valid 10-digit phone number</div>

            </div>
            <div class="row mt-3 mb-0">
              <div class="col-4">
                <label class="receiveConsultation">Pincode <span class="star">*</span></label>
              </div>
              <div class="col-4">
                <label class="receiveConsultation">Radius around pincode(km) <span class="star">*</span></label>
              </div>
              <div class="col-4">
                <label class="receiveConsultation">Consultation charges (Rs.) <span class="star">*</span></label>
              </div>
            </div>
            <div v-for="(area, index) in homeclinicPincode" :key="area.id">
              <div>
                  <span class='cross-icon' >
                      <font-awesome-icon :icon="['fas', 'times-circle']" @click=" showHomePincodePopup(area)" />
                    </span>        
                  </div>
                  <div v-if="isPopupVisibleHomePincode && this.homeclinicPincode.length>1" class="popup">
      <div class="popup-content">
        <p> Are you sure you wish to delete this pincode?</p>
        <div style="display: flex;justify-content: space-evenly;">
          <button @click="deleteHomePincode(area,index)" class="yes-button bg-color">OK</button>
          <button @click="closeHomePincodePopup" class="no-button bg-orange-color">Cancel</button>
        </div>
      </div>
    </div>
    <div v-if="isPopupVisibleHomePincode && this.homeclinicPincode.length<=1" class="popup">
      <div class="popup-content">
        <p>There should be at least one pincode if Home consultation is enabled.</p>
        <div style="display: flex;justify-content: space-evenly;">
          <button @click="closeHomePincodePopup" class="yes-button bg-color">OK</button>
        </div>
      </div>
    </div>
              <div class="row"> 
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control" name="Pincode" @input="handlePinChange" id="Pincode" maxlength="6"  v-model="area.home_pincode" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control" name="Radius" @input="handlePinChange" id="Radius" v-model="area.radius_pincode" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control"  @input="handlePinChange" name="Consultation charges(Rs.)" id="Consultation charges(Rs.)"
                v-model="area.consultation_charges" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
            </div>
          </div>
          <!-- Multiple Post  -->
          <div class="homeclinicmultiple" v-for="(area, index) in listOfarea" :key="index">
            <div class="row " v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive  && addhome">
           <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3"> 
              <input type="text" class="form-control" name="Pincode" id="Pincode"  @input="handlePinChange" maxlength="6"  v-model="area.home_pincode" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control" name="Radius" id="Radius"  @input="handlePinChange" v-model="area.radius_pincode" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control"  @input="handlePinChange" name="Consultation charges(Rs.)" id="Consultation charges(Rs.)"
                v-model="area.consultation_charges" @click="getDataToEditPincodes(area,dropdown)"/>
            </div>
          </div>
          </div>
          <div class="row" v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive">
          <div class="col-9 col-lg-11 col-md-11 col-sm-10">
            <hr class="hr-border" v-bind:class="[popupAllClinic||isPopupVisibleClinic || isPopupVisibleHomePincode||isPopupVisibleHome ? 'hideOpacity' : '']"/>
          </div>
          <div class="col-3 col-lg-1 col-md-1 col-sm-2">
            <span type="button"  class="btn btn_add" id="btn_homeconsult_add" @click="addEmptyHomeForm()" :class="[areFieldsHomeEmpty ? 'disableCreateBtn' : 'clinicCreateBtn']"
              style="width: 55px; height: 50px; margin-top: -10px; font-size: 30px" :disabled="areFieldsHomeEmpty">
              <font-awesome-icon :icon="['fas', 'plus-circle']" />
          </span>
          </div>
        </div>
            <div class="row">
            <p>
              We will soon be allowing RxIx users to request doctor consultation at their home. If you are interested in
              offering home consultation, please enable the checkbox below.
            </p>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label class="checkbox-inline">
                <input type="checkbox"  class="me-2" name="wiling" id="willing" :checked="getHomeClinicAccpect"/>
                  I am willing to provide home consultation to RxIx users
              </label>
            </div>
          </div>

      
        </div>
        <button v-if= "isHomeButtonActive" type="button" class="btn btn-blue-color text-white" id="btn_clinicconsult_add"   
        
        
        @click="pincodeEditbutton ? editHomeClinicForm() : addHomeClinicPincode()">Update clinic       
        </button>
      </div>
      </div>
      <!-- Post Home Consultaion  -->
      <div v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive && homeConsultationDoctor.length<1">
      <div class="home_section my-3">
        <div class="box-shadow2 cardBorder">
          <h6 class="my-3 subHeading">Areas where I offer home consultation and charges</h6>
          <div class="row my-3" >
            <div class="col-lg-6 col-md-6 col-12">
              <label class="receiveConsultation">Name of clinic <span class="star">*</span></label>
              <input type="text" v-model="homeClinicName" class="form-control" name="homeClinicName"
                id="homeClinicName" />
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
              <input type="text" v-model="homeClinicMobile" maxlength="10" class="form-control" name="homeClinicMobile"
                id="homeClinicMobile" />
            </div>
           
            <div class="row mt-3 mb-0">
              <div class="col-4">
                <label class="receiveConsultation">Pincode <span class="star">*</span></label>
              </div>
              <div class="col-4">
                <label class="receiveConsultation">Radius around pincode(km) <span class="star">*</span></label>
              </div>
              <div class="col-4">
                <label class="receiveConsultation">Consultation charges (Rs.) <span class="star">*</span></label>
              </div>
            </div>
            <div class="homeclinicmultiple" v-for="(area, index) in listOfarea" :key="index">
            <div class="row " v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive  && addhome">
           <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3"> 
              <input type="text" class="form-control" name="Pincode" id="Pincode" maxlength="6" v-model="area.home_pincode" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control" name="Radius" id="Radius" v-model="area.radius_pincode" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-0 mb-3">
              <input type="text" class="form-control" name="Consultation charges(Rs.)" id="Consultation charges(Rs.)"
                v-model="area.consultation_charges" />
            </div>
          </div>
          </div>
          </div>
          <div class="row" v-if="dropdown.consultation === 'Home consultation' && dropdown.isActive">
          <div class="col-9 col-lg-11 col-md-11 col-sm-10">
            <hr class="hr-border" />
          </div>
          <div class="col-3 col-lg-1 col-md-1 col-sm-2">
            <span type="button"  class="btn btn_add" id="btn_homeconsult_add" @click="addEmptyHomeForm()"
              style="width: 55px; height: 50px; margin-top: -10px; font-size: 30px" :disabled="areFieldsHomeEmpty" :class="[areFieldsHomeEmpty ? 'disableCreateBtn' : 'clinicCreateBtn']">
              <font-awesome-icon :icon="['fas', 'plus-circle']" />
          </span>
          </div>
        </div>
          <!-- end area select -->
          
          <div class="row">
            <p>
              We will soon be allowing RxIx users to request doctor consultation at their home. If you are interested in
              offering home consultation, please enable the checkbox below.
            </p>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label class="checkbox-inline">
                <input type="checkbox" v-model="rxix_users"   class="me-2" name="wiling" id="willing"/>
                  I am willing to provide home consultation to RxIx users
              </label>
            </div>
          </div>
          <div class="row">
              <div class="col-12 col-md-6 col-lg-2 mt-3">
        <button class=" btn btn-blue-color text-white" @click=" saveHomeConsultation()"
        :disabled="areFieldsEmpty" style="width:100%">{{ "Publish my clinic" }}
        </button>
      </div>
          </div>
        </div>
      </div>
    </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2  mt-3 " v-if="teleAndSecondButton">
        <button class="btn btn-blue-color text-white" @click=" saveTeleAndsecondConsultation()"
        style="width:100%">{{ "Update clinics" }}
        </button>
      </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["docData"],
  data() {
    return {
      listOfarea : [],
      isHomeButtonActive:false,
      isButtonEnabled: true,
      clinicForms: [],
      isActive:false,
      doctorData: {},
      mobileError: false,
      mobileHomeError: false,
      selectedPincode: null,
      consultationData: [],
      doctorConultaion: [],
      second_opinion: false,
      tele_consultation: false,
      homeConsult: {},
      homeConsultation:[],
      clinicConsult: [],
      pincodeEditbutton: true,
      clinicConsultationDoctor:[],
      homeConsultationDoctor:[],
      clinicArray:[],
      getConsult: [],
      homeArray:[],
      doctor_id: {},
      homeClinicid:{},
      clinic_website: "",
      clinic_name: "",
      short_name:"",
      current_address:"",
      option_address:"",
      city:"",
      homeClinicId: "",
      district:"",
      state:"",
      homeClinicName: "",
      clinicName:"",
      clinicMobile:"",
      homeclinicPincode: [],
      mobile: "",
      homeclinic: [],
      homeClinicMobile: "",
      doctorPincode: "",
      doctorGetId: '',
      doctorMobile:"",
      home_pincode: "",
      radius_pincode: "",
      consultation_charges: "",
      rxix_users:false,
      editbutton: false,
      addclinic:false,
      addhome:false,
      getHomeClinicAccpect:false,
      currentlyEditedClinic:{},
      currentlyEditedPincodes: {},
      websiteValidationForm: false,
      ishomeclinicFormClose: false,
      payloadArray:[],
      homeClinicObject:{},
      homePinCode:[],
      isPopupVisibleClinic:false,
      isPopupVisibleHomePincode:false,
      isPopupVisibleHome:false,
      teleAndSecondButton:false,
      popupAllClinic:false,
      nameRegex:/^[A-Za-z.\s]+$/,
      pincodeValidation:/^[1-9](\s*\d){5}$/,
      mobileValidation:/^[6-9]\d{9}$/,
      numberRegex:/^[1-9][0-9]*$/,
      websiteRegex:/^(https?|ftp):\/\/(www\.)?[^\s/$.?#].[^\s]*$/i
    };
  },
  computed: {

    

    isFormValid() {
      const mobileValid = this.currentlyEditedClinic.mobile && this.currentlyEditedClinic.mobile.length === 10;
      const pincodeValid = this.currentlyEditedClinic.pincode && this.currentlyEditedClinic.pincode.length === 6;

    return (
      this.currentlyEditedClinic.clinicName &&
      mobileValid &&
      this.currentlyEditedClinic.current_address &&
      this.currentlyEditedClinic.city &&
      pincodeValid
    );
  },

    areFieldsEmpty() {
    const isHomeOrClinicActive = this.consultationData.some(dropdown => {
        return dropdown.isActive && 
               (dropdown.consultation === 'Home consultation' || dropdown.consultation === 'Clinic consultation');
    });

    if (!isHomeOrClinicActive) {
        return true; 
    }

    return this.consultationData.some(dropdown => {
        if (dropdown.isActive) {
    if (dropdown.consultation === 'Home consultation') {
        return this.listOfarea.some(form => {
        return (
            !this.pincodeValidation.test(form.home_pincode) ||
            !this.numberRegex.test(form.radius_pincode) ||
            !this.numberRegex.test(form.consultation_charges) ||
            !this.mobileValidation.test(this.homeClinicMobile)||
            !this.nameRegex.test(this.homeClinicName)
        );
        });
    } else if (dropdown.consultation === 'Clinic consultation') {
      return this.clinicForms.some(form => {
          const isWebsiteValid = !form.clinic_website || this.websiteRegex.test(form.clinic_website);
        return (
            !this.nameRegex.test(form.clinic_name) ||
            !form.current_address||
            !this.nameRegex.test(form.state) ||
            !this.pincodeValidation.test(form.doctorPincode) ||
            !this.mobileValidation.test(form.doctorMobile)||
            !isWebsiteValid
        );
        });
    }
    }
    return false; 
    });
}
,
  areFieldsClinicEmpty(){
    return this.clinicForms.some(form => {
      const isWebsiteValid = !form.clinic_website || this.websiteRegex.test(form.clinic_website);
    return (
      !this.nameRegex.test(form.clinic_name) ||
      !form.current_address||
      !this.nameRegex.test(form.city) || 
      !this.pincodeValidation.test(form.doctorPincode) ||
      !this.mobileValidation.test(form.doctorMobile)||
      !isWebsiteValid
    );
    });
  },
  validateClinicEdit(){
    const isWebsiteValid = !this.currentlyEditedClinic.clinicWebsite || this.websiteRegex.test(this.currentlyEditedClinic.clinicWebsite);
    return (
      !this.nameRegex.test(this.currentlyEditedClinic.clinicName) ||
      !this.currentlyEditedClinic.current_address||
      !this.nameRegex.test(this.currentlyEditedClinic.city) || 
      !this.nameRegex.test(this.currentlyEditedClinic.district) ||
      !this.nameRegex.test(this.currentlyEditedClinic.state) ||
      !this.pincodeValidation.test(this.currentlyEditedClinic.pincode) ||
      !this.mobileValidation.test(this.currentlyEditedClinic.mobile)||
      !isWebsiteValid
    );
  },
  areFieldsHomeEmpty() {
    return this.listOfarea.some(form => {
      return !this.pincodeValidation.test(form.home_pincode) ||
          !this.numberRegex.test(form.radius_pincode) ||
        !this.numberRegex.test(form.consultation_charges) 
    });
  },
  areFieldsHomeEmptyInEdit() {
    return this.listOfarea.some(form => {
    return !this.pincodeValidation.test(form.home_pincode) ||
            !this.numberRegex.test(form.radius_pincode) ||
            !this.numberRegex.test(form.consultation_charges) ||
            !this.mobileValidation.test(this.homeClinicMobile)||
            !this.nameRegex.test(this.homeClinicName)
  });
  },
  validateHomeEdit() {
    return !this.pincodeValidation.test(this.currentlyEditedPincodes.home_pincode) ||
            !this.numberRegex.test(this.currentlyEditedPincodes.radius_pincode) ||
            !this.numberRegex.test(this.currentlyEditedPincodes.consultation_charges) ||
            !this.mobileValidation.test(this.homeClinicMobile)||
            !this.nameRegex.test(this.homeClinicName)
 
  },
    clinicConsultationActive() {
      return this.consultationData.some(
        (dropdown) =>
          dropdown.consultation === 'Clinic consultation' && dropdown.isActive
      );
    },
    homeConsultationActive() {
      return this.consultationData.some(
        (dropdown) =>
          dropdown.consultation === 'Home consultation' && dropdown.isActive
      );
    },
  },
  

  methods: {

    updateButtonState(){
      
      this.teleAndSecondButton=true
    },
    validatePhoneInput(event) {
      
    event.target.value = event.target.value.replace(/\D/g, '');
    this.clinicConsult.mobile = event.target.value;
    this.mobileError = this.clinicConsult.mobile.length !== 10;

    this.isButtonEnabled = false 
  },

  validatePincodeInput(event){
    event.target.value = event.target.value.replace(/\D/g, '');
    this.clinicConsult.pincode =event.target.value
    this.isButtonEnabled = false 


  },
    deleteHome(item,value){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
          .delete(`${BASE_API_URL}/doctorconsultation/${value.doctorconsultationid}/delete`)
          .then((response)=>{
        if(response.data){
          axios
          .delete(`${BASE_API_URL}/home-consultation-pincode/${item}/homeId`)
            .then((response) => {
            if(response.data){
            axios
            .delete(`${BASE_API_URL}/home-consultation/${item}/delete`)
            .then((response)=>{
              if(response.data){
                this.closeHomePopup();
                this.getHomeConsultation() ;
                this.getConultationData();
              }
            })
            }
                })
            }})
        .catch(function (error) {
          return error
        });
    },
    deleteHomePincode(){

      if (!this.selectedPincode) return; 


      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        axios
          .delete(`${BASE_API_URL}/home-consultation-pincode/${this.selectedPincode.id}/pincodeId`)
          .then((response) => {
          if(response.data){
            this.closeHomePincodePopup();
            this.getHomeConsultation() ;
          }
          })
          .catch(function (error) {
            return error
          });
    },
    closeHomePopup(){
     this.isPopupVisibleHome=false
    },
    showHomePincodePopup(area){
      this.selectedPincode = area;
        this.isPopupVisibleHomePincode=true
    },
    closeHomePincodePopup(){
     this.isPopupVisibleHomePincode=false
    },
    showPopup() {
        this.isPopupVisibleClinic = true;
    },
    closePopup(){
      this.isPopupVisibleClinic = false;
    },
    deleteAllClinic(data){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        axios
          .delete(`${BASE_API_URL}/doctorconsultation/${data.doctorconsultationid}/delete`)
          .then((response)=>{
            if(response.data){
              axios
                .delete(`${BASE_API_URL}/clinic-consultation/${this.doctor_id}/delete-hcpclinic`)
                .then((response) => {
                if(response.data){
                  this.getClinicConsultation() ;
                  this.getConultationData();
                  this.closePopup();
                  this.popupAllClinic=false
                }
                })
                .catch(function (error) {
                  return error
                });
            }
          })
    },
    clinicAllPopup(){
      this.popupAllClinic=false
    },
    deleteClinic(data){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if(this.clinicConsultationDoctor.length>1){

        axios
          .delete(`${BASE_API_URL}/clinic-consultation/${data.clinicid}/delete`)
          .then((response) => {
          if(response.data){
            this.getClinicConsultation() ;
            this.closePopup();
          }
  
          })
          .catch(function (error) {
            return error
          });
      }
    },
    showClinicWebsite(index) {
      return index === 0; 
    },
    handleInputChange(event) {
      this.clinicName =this.homeClinicName,
    event.target.value = event.target.value.replace(/\D/g, '');

      this.pincodeEditbutton = true;
    this.clinicMobile=this.homeClinicMobile
    this.mobileHomeError = this.clinicMobile.length !== 10;
    
    this.isHomeButtonActive = this.clinicMobile.length === 10;

    
  }
,
  handleclinicNameChange() {
      this.clinicName =this.homeClinicName,
    this.isHomeButtonActive = true

  }
  ,

  handlePinChange(event){
    this.isHomeButtonActive=true
    event.target.value = event.target.value.replace(/\D/g, '');


  },
    isHomeOrClinicChecked() {
      return this.consultationData.some((dropdown) => {
       return (dropdown.consultation === 'Home consultation' || dropdown.consultation === 'Clinic consultation') && dropdown.isActive;
            });
        },
      getHomeOrClinic(event,consultation){
      if(consultation.consultation === 'Clinic consultation'){
      this.clinicConsultationActive=true
      if( event?.target.checked && this.clinicConsultationDoctor.length === 0){
          this.addEmptyClinicForm()
      }else{
        this.clinicForms = [];
      }
      }
      else if(consultation.consultation === 'Home consultation'){
      this.homeConsultationActive=true
      if( event?.target.checked && this.homeConsultationDoctor.length === 0){
      this.listOfarea=[];
      this.addEmptyHomeForm()
      } 
      }
},
    getHomeOrClinicChange(event,consultation){

      this.doctorConultaion.map((data)=>{
        if(data.consultation === 'Clinic consultation' && consultation.consultation=='Clinic consultation'){
          this.popupAllClinic = true;
           consultation.isActive=true
        }else if(data.consultation === 'Home consultation' && consultation.consultation=='Home consultation'){
          this.isPopupVisibleHome = true;
          consultation.isActive=true
        }
      })      
      if(consultation.consultation === 'Clinic consultation'){
        this.clinicConsultationActive=true
        
        if( event?.target.checked && this.clinicConsultationDoctor.length === 0){
           this.addEmptyClinicForm()
        }else{
          this.clinicForms = [];
        }
      }
      else if(consultation.consultation === 'Home consultation'){
        this.homeConsultationActive=true
       if( event?.target.checked && this.homeConsultationDoctor.length === 0){
        this.listOfarea=[];
        this.addEmptyHomeForm()
       } 
      }
    },
    addEmptyClinicForm() {
      if(this.clinicForms?.length > 0 ){
        this.websiteValidationForm = false;
        this.clinicForms.push({
        
      clinic_name: '',
      short_name:'',
      current_address: '',
      option_address: '',
      city: '',
      district: '',
      state: '',
      doctorPincode:  '',
      doctorMobile:  ''
    })
   
      }else{
        this.websiteValidationForm = true;
        this.clinicForms.push({
      clinic_website: '',
      clinic_name: '',
      short_name:'',
      current_address: '',
      option_address: '',
      city: '',
      doctorPincode: this.clinicConsultationDoctor.length === 0   ? this.doctorPincode : '',
      doctorMobile: this.clinicConsultationDoctor.length === 0 ? this.doctorMobile : ''
    })
      }
      this.addclinic=true
      this.editbutton = false

  if (this.clinicConsultationDoctor.length >= 1) {
    this.doctorPincode = "";
    this.doctorMobile = "";
  }
    },
    addEmptyHomeForm(){
      this.isHomeButtonActive=true
      this.getHomeConsultation() ;
      this.pincodeEditbutton = false;
      this.listOfarea.push({
        home_pincode: '',
        radius_pincode: '',
        consultation_charges: ''
      });
      this.addhome=true
    },
    GoSetting() {
      window.scrollTo(0, 0);
      this.$router.push(`/doctor/mobile-settings`);
    },
 async saveClinicConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
       this.listOfarea.map(value => {this.homePinCode=value})
      const selectedConsultations = this.consultationData.filter(item => item.isActive);
      if(!this.areFieldsClinicEmpty){
      for (let i = 0; i < this.clinicForms.length; i++) {  
    let clinicPayload = {
          clinic_website: this.clinicForms[0].clinic_website,
          clinic_name: this.clinicForms[i].clinic_name,
          short_name: this.clinicForms[i].short_name,
          pincode: this.clinicForms[i].doctorPincode,
          mobile: this.clinicForms[i].doctorMobile,
          current_address:this.clinicForms[i].current_address,
          option_address:this.clinicForms[i].option_address,
          city:this.clinicForms[i].city,
          tele_consultation: this.tele_consultation,
          second_opinion: this.second_opinion,
          is_publish_clinic:true,
          hcps_id: this.doctor_id,
        };
        await axios
          .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.addclinic=false
            this.$swal(" clinic-consultation Record is created");
            this.clinicConsultationDoctor=[]
            this.getClinicConsultation() ;
            this.getConultationData();
            return response.data


          }
          )
          .catch((error) => {
            return error
            });
        let consultationPayload = {
        Doctorconsultaion: selectedConsultations,
        updated_by:'doctor',
      };
            await axios
        .put(`${BASE_API_URL}/hcps/${this.doctor_id}/update`, consultationPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.getConultationData();
            return response.data
          }
        })
          }
          this.clinicForms=[]
        }
     
    },

    async saveTeleAndsecondConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
       this.listOfarea.map(value => {this.homePinCode=value})
      const selectedConsultations = this.consultationData.filter(item => item.isActive);
      if(!this.areFieldsClinicEmpty){
    let clinicPayload = {
      tele_consultation: this.tele_consultation,
      second_opinion: this.second_opinion,
        }
        await axios
          .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.addclinic=false
            this.$swal(" Record is saved");
            this.getClinicConsultation() ;
            this.getConultationData();
      this.teleAndSecondButton=false

            return response.data


          }
          )
          .catch((error) => {
            return error
            });
        let consultationPayload = {
        Doctorconsultaion: selectedConsultations,
        updated_by:'doctor',
      };
            await axios
        .put(`${BASE_API_URL}/hcps/${this.doctor_id}/update`, consultationPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.getConultationData();
            return response.data
          }
        })
          this.clinicForms=[]
        }
     
    },

    

    async saveHomeConsultation() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  const selectedConsultations = this.consultationData.filter(item => item.isActive);

  if (!this.areFieldsHomeEmpty) {
    if (this.homeConsultationDoctor.length < 1) {
      let payload = {
        clinic_name: this.homeClinicName,
        mobile: this.homeClinicMobile,
        rxix_users: this.rxix_users,
        hcps_id: this.doctor_id
      };

      try {
        const homeConsultationResponse = await axios.post(`${BASE_API_URL}/home-consultation`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (homeConsultationResponse.data) {
          const homeConsultationData = homeConsultationResponse.data;

          this.homeClinicObject = {
            id: homeConsultationData.id,
            hcps_id: homeConsultationData.hcps_id,
            createdAt: homeConsultationData.createdAt,
            clinic_name: homeConsultationData.clinic_name,
            mobile: homeConsultationData.mobile,
            updateAt: homeConsultationData.updateAt
          };

          for (let i = 0; i < this.listOfarea.length; i++) {
            let homeDataPayload = {
              home_pincode: this.listOfarea[i].home_pincode,
              radius_pincode: this.listOfarea[i].radius_pincode,
              consultation_charges: this.listOfarea[i].consultation_charges,
              home_id: this.homeClinicObject.id 
            };
            
            await axios.post(`${BASE_API_URL}/home-consultation-pincode`, homeDataPayload, {
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
          
          const query = this.$route.query;
          this.listOfarea = [];
          this.addhome = false;
          this.$swal("Home consultation record is created");
          this.$router.push({ path: `consultation-place`, query });

setTimeout(() => {
  window.scrollTo(0, 0);
  window.location.reload();
}, 100); 
          await this.getHomeConsultation();
          await this.getConultationData();
        }

        let consultationPayload = {
          Doctorconsultaion: selectedConsultations,
          updated_by: 'doctor',
        };

        await axios.put(`${BASE_API_URL}/hcps/${this.doctor_id}/update`, consultationPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

      } catch (error) {
        console.error("Error while saving home consultation:", error);
      }
    }
  }
}
,
    getDataToEdit(){
      this.clinicConsultationDoctor.isEditable = true
    },
    getDataToEditConsultation(datas,index){
      datas.isedit=true
      this.editbutton = true
let oldConsultation= [... this.clinicConsultationDoctor]
oldConsultation[index].isEditable=true
this.currentlyEditedClinic = datas;

    },
    getDataToEditPincodes(datas){
this.currentlyEditedPincodes = datas;
    },
    async addHomeClinicPincode(){
       this.listOfarea.map(value => {this.homePinCode=value})
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      for (let i = 0; i < this.listOfarea.length; i++) {
          let homeDataPayload={
              home_pincode:this.listOfarea[i].home_pincode,
              radius_pincode:this.listOfarea[i].radius_pincode,
              consultation_charges:this.listOfarea[i].consultation_charges,
              id:this.homeConsultationDoctor?.id,
              home_id :  this.homeConsultationDoctor[0].homeClinicid
            }
            axios
            .post(`${BASE_API_URL}/home-consultation-pincode`, homeDataPayload, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if(response.data){
                this.listOfarea=[];
                this.homeId = response.data.id;
                this.addhome=false
                this.isHomeButtonActive=false
                this.$swal("Record created");
            this.getHomeConsultation() ;
              }
            })
          } 
        },

    async editHomeClinicForm(){
      
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      try{
      let homePayload = {
          clinic_name: this.homeClinicName,
          mobile: this.homeClinicMobile,
          rxix_users: this.getHomeClinicAccpect,
          hcps_id: this.doctor_id
        };
      const query = this.$route.query;

        await axios
          .put(`${BASE_API_URL}/home-consultation/${this.homeConsultationDoctor[0].homeClinicid}/update`, homePayload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
  this.isHomeButtonActive = false;                                                
  this.getHomeConsultation();

  this.$swal("Record saved");

  this.$router.push({ path: `consultation-place`, query });

  setTimeout(() => {
    window.scrollTo(0, 0);
    window.location.reload();
  }, 100); 
}

          })
          .catch((error) => {
            return error
          });
        if (this.currentlyEditedPincodes) {
      const homeConsultPincode = this.currentlyEditedPincodes;
      let pincodePayload = {
               home_pincode:homeConsultPincode.home_pincode,
               radius_pincode:homeConsultPincode.radius_pincode,
               consultation_charges:homeConsultPincode.consultation_charges,
               home_id :  this.homeConsultationDoctor[0].homeClinicid
      };
        await axios
          .put(`${BASE_API_URL}/home-consultation-pincode/${homeConsultPincode.id}/update`, pincodePayload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
              this.$swal("Record saved");
            }
          })
          .catch((error) => {
            return error
          });
        }
      }
      catch(error){
   
        this.$swal("Enter the mandatory fields correctly")
      }
        
    },
    async updateConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.validateClinicEdit ) {
      const clinicConsult = this.currentlyEditedClinic;
      let clinicPayload = {
        clinic_website: clinicConsult.clinicWebsite,
        clinic_name: clinicConsult.clinicName,
        short_name:clinicConsult.short_name,
        current_address: clinicConsult.current_address,
        option_address: clinicConsult.option_address,
        city: clinicConsult.city,
        is_publish_clinic:true,
        district: clinicConsult.district,
        state: clinicConsult.state,
        pincode: clinicConsult.pincode,
        mobile: clinicConsult.mobile,
      };
      const query = this.$route.query;

        await axios
          .put(`${BASE_API_URL}/clinic-consultation/${clinicConsult.clinicid}/update`, clinicPayload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
              this.addclinic=false
              this.getClinicConsultation() ;
              this.$swal("Clinic updated !");
      window.scrollTo(0, 0);
      this.$router.push({path: `/doctor/index`, query })
            }
          })
          .catch((error) => {
            return error
          });
    }
      else{
        this.$swal("Enter the mandatory fields correctly");
      }
      
    },
    getHomeConsultation(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/home-consultation`)
        .then((response) => {
        this.homeConsultationDoctor=[]
          const homeclinic = response.data.filter(item => { 
            return item.hcps_id?.id === this.doctor_id 
          });
          this.homeClinicId = homeclinic[0]?.id
            axios
        .get(`${BASE_API_URL}/home-consultation-pincode`)
        .then((response) => {
        // this.homeConsultationDoctor=[]
          this.homeConsultation = response.data;
         this.homeclinicPincode = response.data.filter(item => item?.home_id?.hcps_id?.id === this.doctor_id);
            });
            this.homeArray =homeclinic
          this.homeArray.map((data)=>{
            let homeData
            homeData ={
             homeClinicName : data.clinic_name,
             homeClinicMobile : data.mobile,
             home_pincode :data.home_pincode,
             radius_pincode : data.radius_pincode,
             consultation_charges : data.consultation_charges,
             rxix_users : data.rxix_users,
             homeClinicid:data.id
            }
            this.homeConsultationDoctor.push(homeData)
            const mappedArray = this.homeConsultationDoctor.map(home => {
          return {
            homeClinicName: home.homeClinicName,
            homeClinicMobile: home.homeClinicMobile,
            homeClinicid: home.homeClinicid,
            homeClinicCheckBox:home.rxix_users
          };
        });
        if (mappedArray.length > 0) {
        mappedArray.reduce((lowest, current) => {
            if (current.homeClinicid <= lowest.homeClinicid) {
            this.homeClinicName = current.homeClinicName
            this.homeClinicMobile = current.homeClinicMobile
            this.getHomeClinicAccpect=current.homeClinicCheckBox
            this.homeClinicid = current.homeClinicid
              return current;
            } else {
              return lowest;
            }
          }, mappedArray[0]); 
          }
    
          })
        })
    },
    async getDoctorData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/hcps/${this.doctor_id}/one`)
        .then((response) => {
          this.doctorData = response.data
          
          this.doctorGetId = this.doctorData?.clinic_hcp_id
          this.doctorPincode = this.doctorData.pincode?.id
          this.doctorMobile= this.doctorData.mobile
          this.doctorConultaion = this.doctorData.Doctorconsultaion;
this.consultationData.forEach(item => {
  const matchingDoctor = this.doctorConultaion.find(doctor => doctor.id === item.id);
  if (matchingDoctor) {
    item.doctorconsultationid = matchingDoctor.doctorconsultationid;
  } else {
    item.doctorconsultationid = null; // or any default value if not found
  }
  item.isActive = !!matchingDoctor;
});
        })
    },
    getClinicConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/clinic-consultation`)
        .then((response) => {
          this.clinicConsultationDoctor=[]
          this.clinicConsult = response.data;
            const createdData = this.clinicConsult.filter(item => {
            return item.hcps_id?.id === this.doctor_id 
            });
            const getConsultation = this.clinicConsult.filter(item => {
            return item?.id == this.doctorData?.clinic_hcp_id 
            });
            const getClinicAdmin = this.clinicConsult.filter(item => {
            return item?.id == this.doctorData?.clinic_admin_id 
            });
            this.getConsult = getConsultation
            this.clinicArray =createdData.concat(getConsultation,getClinicAdmin)
            this.clinicArray.map((item)=>{
            let consultData;
            consultData = {
                clinicName:item.clinic_name,
                short_name:item.short_name,
                clinicWebsite:item.clinic_website,
                current_address:item.current_address,
                option_address:item.option_address,
                city:item.city,
                district:item.district,
                state:item.state,
                pincode:item.pincode,
                mobile:item.mobile,
                is_publish_clinic:item.is_publish_clinic,
                clinicid:item.id,
                isedit:false
                }
        this.clinicConsultationDoctor.push(consultData)
        const mappedArray = this.clinicConsultationDoctor.map(clinic => {
      return {
        clinicName: clinic.clinicName,
        mobile: clinic.mobile,
        clinicid: clinic.clinicid
      };
    });
    
      if (mappedArray.length >= 0) {
      mappedArray.reduce((lowest, current) => {
          if (current.clinicid <= lowest.clinicid) {
          this.homeClinicName = current.clinicName
          this.homeClinicMobile = current.mobile
            return current;
          } else {
            return lowest;
          }
        }, mappedArray[0]); 
        }
 })
  })
    },
    
    async getConultationData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/consultation`)
        .then((response) => {
          this.consultationData = response.data;
          console.log(this.consultationData,"this.consultationData")
        })
      this.getDoctorData();
    },
  },
  created: async function () {
    let doctorID = this.docData ? this.docData.id : localStorage.getItem('id');
     this.doctor_id = JSON.parse(doctorID);
     await this.getDoctorData();
     await this.getClinicConsultation();
     await this.getHomeConsultation();
    this.getConultationData();
    
  },
};
</script>
<style>
.allfieldcontent{
color: red;
margin-top: 15px
}
.star {
    color: red;
    font-size: 20px;
}
.hideOpacity{
  opacity: 0;
}
.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.yes-button {
  background-color: #38761D;
  color: #fff;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

.no-button {
  background-color: #FF9900;
  color: #fff;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  text-align: center;
  background: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.cross-icon{
  font-size:25px;
  color: #cd381c;
  cursor: pointer;
  float: right;
}
.clinicCreateBtn{
  color: #00979e !important;
  cursor: pointer;
}
.homeclinicmultiple{
  width: 98%;
}
.is-invalid {
  border-color: red;
}
.text-danger {
  color: red;
}

button#btn_homeconsult_add {
    border-color: transparent;
}

button#btn_clinicconsult_add {
    border-color: transparent;
}

.disableCreateBtn{
  color:  #dddddd !important;
  pointer-events: none;
  cursor: auto; 
}
.clinicTextAlign input{
  text-align: left !important;;
}
.box-shadow2 {
  box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
}
.cardBorder {
  padding: 15px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 20px;
}
/* .receiveConsultation {
  color: #34989f;
}

.checkbox-inline {
  color: #34989f;
} */
.checkbox-inline.heading {
  font-size: 20px;
  margin-bottom: 0px;
}

.my-3.subHeading {
  /* color: #34989f; */
  font-size: 18px;
}

.ms-5.text-color {
  /* color: #34989f; */
  font-size: 18px;
}
@media screen and (max-width:991px) {
  .mobileStyleDoctor {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 6rem;
  }
  .mypracticeMobile {
    margin-left: 20px;
    /* margin-bottom: 10px; */
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }
}
</style>
